export function createModalRoot() {
  const el = document.getElementById("a8ChatModal")
  if(!el) {
    const modal = document.createElement("div");
    modal.id = "a8ChatModal";
    modal.classList.add("a8modal")
    document.body.appendChild(modal);
  }
}
const processedData = new Set();
export function openModal(data) {
  if (processedData.has(data.picture ? data.picture : data.url)) {
    return;
  }
  processedData.add(data.picture ? data.picture : data.url);
  const modal = document.getElementById("a8ChatModal");
  modal.style.zIndex = parseInt(data.theme.zIndex) + 10;
  modal.style.fontFamily = data.theme.font;
  const modalDiv = document.createElement("div");

  let htmlStr = ""
  if (data.type === "image") {
    htmlStr = `
      <div class="a8modal-content">
      <span class="a8close">&times;</span>
        <div class="a8modal-body">
          <img class="background" width="100%" src="${data.picture}" />
        </div>
      </div>
      `;
  } else if(data.type === "pdf") {
    htmlStr = `
      <div class="a8modal-content" style="height: 75vh">
      <span class="a8close">&times;</span>
        <div class="a8modal-body" style="height: 100%; max-height: 90%">
          <object data='${data.url}' 
            type='application/pdf' 
            width='100%'
            height='100%'
            >
            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${data.url}">Download PDF</a></p>
          </object>
        </div>
      </div>
      `;
  }

  modalDiv.insertAdjacentHTML("beforeend", htmlStr);
  modal.appendChild(modalDiv);
  modal.style.display = "block";

  const modalContent = document.querySelector(".a8modal-content");
  if(modalContent) {
    modalContent.style.transform = "translateY(200%)"
    modalContent.style.transform = "translateY(0)"
  }
  const closeIcon = document.querySelector(".a8close");
  if (closeIcon) {
    closeIcon.onclick = function () {
      modalDiv.parentNode.removeChild(modalDiv);
      modal.style.display = "none";
      processedData.clear();
    };
  }
  window.onclick = function (event) {
    if (event.target === modal) {
      modalDiv.parentNode.removeChild(modalDiv);
      modal.style.display = "none";
      processedData.clear();
    }
  };
}
